import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
import { Link } from 'react-router-dom';
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import $ from 'jquery';
DataTable.use(DT);

const Users = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

   

    const [toastMessage, setToastMessage] = useState(null);

    useEffect(() => {
        // Check if there is a toast message in localStorage when the component mounts
        const message = localStorage.getItem('toastMessage');
        if (message) {
            setToastMessage(message); // Set the message in the state
            localStorage.removeItem('toastMessage'); // Clear the message from localStorage
        }
    }, []); // Empty dependency array ensures this runs only once on mount

    useEffect(() => {
        if (toastMessage) {
            toast.success(toastMessage); // Display the toast message
        }
    }, [toastMessage]); // Display toast when message is available

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${API_BASE_URL}/Users/get_users`);
                setData(response.data.results);
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error("Error fetching data!");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [API_BASE_URL]);

    const columns = [
        { title: 'SL.No.', data: 'count' },
        {
            title: 'Action',
            render: (data, type, row) => {
                return `<a href="/edit-user?id=${row.mst_users_id}" class="btn_edit">Edit</a>`;
            }
        },
        { title: 'Employee ID', data: 'employee_id' },
        { title: 'Employee Name', data: 'employee_name' },
        { title: 'Employee E-mail', data: 'employee_email' },
        { title: 'Employee Mobile', data: 'employee_mobile' },
        { title: 'Department', data: 'mst_departments_id' },
        { title: 'Role', data: 'mst_roles_id' },
        { title: 'Delegate Employees', data: 'delegate_employees' },
        {
            title: 'Status',
            render: (data, type, row) => {
                let badgeClass = '';
                const status = (row.status || '').trim().toLowerCase();
                if (status === 'active') {
                    badgeClass = 'badge bg-success';
                } else if (status === 'inactive') {
                    badgeClass = 'badge bg-danger';
                } else {
                    badgeClass = 'badge bg-secondary';
                }
                return `<span class="${badgeClass}">${row.status}</span>`;
            }
        },
        { title: 'Created On', data: 'create_on' },
        { title: 'Created By', data: 'created_by' },
    ];

    useEffect(() => {
        $(".users_table").wrap("<div class='wrap_table'></div>");
        $(".wrap_table").css({
            "overflow-x": "auto",
        });
    }, [data]);

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Users Master</h4>
                                <Link to="/add-user" className="btn btn-primary">Create New User</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {loading ? (
                                // Loader component while data is loading
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                // DataTable when data is loaded
                                <DataTable
                                    data={data}
                                    columns={columns}
                                    className="display custom-table users_table"
                                    paging={true}
                                    ordering={true}
                                    responsive={true}
                                    language={{
                                        loadingRecords: loading ? "Loading..." : "No data available",
                                        search: "",
                                        searchPlaceholder: "Search users..."
                                    }}
                                    options={{
                                        pageLength: 10,
                                        lengthChange: true,
                                        lengthMenu: [10, 25, 50, 100],
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Users;
