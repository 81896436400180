
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Dashboard = () =>{
    const navigate = useNavigate();
    
    const [greeting, setGreeting] = useState('');
    const full_name = localStorage.getItem("full_name");
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [employees, setEmployees] = useState([]);
    //const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
            setGreeting('Good Morning');
        } else if (currentHour < 18) {
            setGreeting('Good Afternoon');
        } else {
            setGreeting('Good Evening');
        }
    }, []);

    useEffect(() => {
        const fetchdata = async () => {
            try {
                const formData1 = new FormData();
                formData1.append("user_id", localStorage.getItem("mst_users_id"));

                const response = await axios({
                    method: 'POST',
                    url: `${API_BASE_URL}/Users/get_users_list`,
                    data: formData1,
                    headers: { "Content-Type": "multipart/form-data" }
                });

                const userData = response.data.results[0];
                setEmployees(userData);
            } catch (error) {
                console.error('Error fetching user data:', error);
              
            }
         
        };

        fetchdata();
    }, [API_BASE_URL]);


    
    const handleSubmit = async () => {
      
        if(!confirm("Are you sure want to Change the password..?")){
            return false;
        }

        try {
            const formData1 = new FormData();
           
            formData1.append("old_password", document.getElementsByClassName("old_password")[0]?.value || "");
            formData1.append("new_password", document.getElementsByClassName("new_password")[0]?.value || "");
            formData1.append("confirm_password", document.getElementsByClassName("confirm_password")[0]?.value || "");
            formData1.append("email", document.getElementsByClassName("email_id")[0]?.value || "");
            formData1.append("mst_users_id", localStorage.getItem("mst_users_id") || "");
          
            const apiEndpoint = `${API_BASE_URL}/Users/changepassword_web`;
            const method =  'POST';
            const response = await axios({
                method: method,
                url: apiEndpoint,
                data: formData1,
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            console.log("response "+response);
            if (response.data.success == 1) {
                toast.success(response.data.message);            
                localStorage.clear(); // Clear local storage
                navigate('/'); // Redirect to the homepage
                localStorage.setItem('toastMessage', response.data.message);
                // window.location.href = '/Users';

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during submission:', error);
        }


    };


    return(
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    {/* <!-- start page title --> */}
                    <div className="row mb-3 pb-1 ">
                        <div className="col-12">
                            <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                                <div className="flex-grow-1">
                                    <h4 className="fs-16 mb-1">{greeting}, {full_name}!</h4>
                                   
                                </div>
                                <div className="mt-3 mt-lg-0">                                    
                                </div>
                            </div>
                            {/* <!-- end card header --> */}
                        </div>                        
                    </div>
                    <form  id="myForm" onSubmit={() => handleSubmit()}>
                  
                        <div className="row">
                        
                            <div className="col-xxl-3 col-md-6 form-group">
                                <div>
                                    <label for="basiInput" className="form-label">User Name</label>
                                    <input type="text" className="form-control"  value={employees.employee_name} readOnly id="basiInput"/>
                                </div>
                            </div>
                            
                            <div className="col-xxl-3 col-md-6 form-group">
                                <div>
                                    <label for="basiInput" className="form-label">Email Id</label>
                                    <input type="text" className="form-control email_id" value={employees.employee_email} readOnly id="basiInput"/>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-md-6 form-group">
                                <div>
                                    <label for="basiInput" className="form-label">Role Name</label>
                                    <input type="text" className="form-control" value={employees.role_name} readOnly  id="basiInput"/>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-md-6 form-group">
                                <div>
                                    <label for="basiInput" className="form-label">Department Name</label>
                                    <input type="text" className="form-control" value={employees.department_name} readOnly id="basiInput"/>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-md-6 form-group">
                                <div>
                                    <label for="basiInput" className="form-label">Old Password</label>
                                    <input type="text" className="form-control old_password"  id="basiInput"/>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-md-6 form-group">
                                <div>
                                    <label for="basiInput" className="form-label">New Password</label>
                                    <input type="text" className="form-control new_password"  id="basiInput"/>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-md-6 form-group">
                                <div>
                                    <label for="basiInput" className="form-label">Confirm Password</label>
                                    <input type="text" className="form-control confirm_password"  id="basiInput"/>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-md-6 form-group">
                                <div>
                                <button type='button' onClick={() => handleSubmit()} className='btn btn-sm btn-primary mt-4'>Submit</button>
                                </div>
                            </div>
                        </div> 
                    </form>
                    {/* <!-- end page title --> */}
                </div>
                {/* <!-- container-fluid --> */}
            </div>
            {/* <!-- End Page-content --> */}
            
        </div>
            
    )
}

export default Dashboard;