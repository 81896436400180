
import React, { useState } from "react";
import { addWeeks, subWeeks, startOfWeek, format, addDays, differenceInWeeks } from "date-fns";

//import { useLocation } from "react-router-dom";

// Function to calculate the financial year weeks with "filter" prefix
const App = () => {
    const [isLoading, setIsLoading] = useState(false); // Loader state
    setIsLoading(true);
    
//   const useQuery = () => {
//     return new URLSearchParams(useLocation().search);
//   };
  //const query = useQuery();
 // const id = query.get("id");

 // const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


  const getFinancialYearStart = (date) => {
    // Financial year starts on April 1st
    const year = date.getMonth() >= 3 ? date.getFullYear() : date.getFullYear() - 1;
    return new Date(year, 3, 1); // April 1st of the given year
  };

  const getFinancialWeek = (date) => {
   console.log("get financial week date "+date);
    const financialYearStart = getFinancialYearStart(date); // April 1 of the financial year
    const financialYearEnd = new Date(financialYearStart.getFullYear() + 1, 2, 31); // March 31 of the next year
  
    if (date >= financialYearStart && date <= financialYearEnd) {
      // Calculate week number from the start of the financial year
      const weekStart = startOfWeek(financialYearStart, { weekStartsOn: 1 });
      return differenceInWeeks(startOfWeek(date, { weekStartsOn: 1 }), weekStart) + 1;
    } else if (date > financialYearEnd) {
      // Handle transition to the next financial year
      const nextFinancialYearStart = new Date(financialYearStart.getFullYear() + 1, 3, 1); // April 1 of next year
      const nextWeekStart = startOfWeek(nextFinancialYearStart, { weekStartsOn: 1 });
      return differenceInWeeks(startOfWeek(date, { weekStartsOn: 1 }), nextWeekStart) + 1;
    } else {
      // If date is before the current financial year (unlikely in your case)
      return 0;
    }
    
  };
  
  
  const [currentWeekStart, setCurrentWeekStart] = useState(new Date());

  const navigateWeek = (direction) => {
    if (direction === "prev") {
      setCurrentWeekStart((prev) => {
        const newStart = subWeeks(prev, 1);
      
        return newStart;
      });
    } else {
      setCurrentWeekStart((prev) => {
        const newStart = addWeeks(prev, 1);
       
        return newStart;
      });
    }
  };


  const getWeekDates = () => {
    const weekDates = [];
    const startOfWeekDate = startOfWeek(currentWeekStart, { weekStartsOn: 1 });
    for (let i = 0; i < 5; i++) {
      weekDates.push(addDays(startOfWeekDate, i));
    }
    return weekDates;
  };

  const weekDates = getWeekDates();

   //console.log("currentWeekStart "+currentWeekStart);
  const financialWeek = getFinancialWeek(currentWeekStart);
  //console.log("financialWeek "+financialWeek);

    const filterCalculateFinancialYearWeeks = (filterYear, filterMonth) => {
        const filterIsPreviousFinancialYear = filterMonth === '01' || filterMonth === '02' || filterMonth === '03';
        const filterFinancialYearStart = new Date(`${filterIsPreviousFinancialYear ? filterYear - 1 : filterYear}-04-01`);
        
        const filterWeekNumbers = [];
        const filterStartDate = new Date(`${filterYear}-${filterMonth < 10 ? '0' + filterMonth : filterMonth}-01`);
        const filterEndDate = new Date(filterStartDate.getFullYear(), filterMonth, 0);
      
        let filterCurrentDate = filterStartDate;
        while (filterCurrentDate <= filterEndDate) {
          const filterFirstDayOfWeek = new Date(filterCurrentDate);
          filterFirstDayOfWeek.setDate(filterCurrentDate.getDate() - filterCurrentDate.getDay());
      
          const filterTimeDifference = filterFirstDayOfWeek - filterFinancialYearStart;
          let filterWeekNumber = Math.ceil((filterTimeDifference / (1000 * 3600 * 24)) / 7) + 1;
      
          filterWeekNumbers.push({
            week: filterWeekNumber,
            startDate: filterFirstDayOfWeek.toISOString().split('T')[0],
            endDate: new Date(filterFirstDayOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
          });
      
          filterCurrentDate.setDate(filterCurrentDate.getDate() + 7);
        }
      
        return filterWeekNumbers;
    };

    const [filterYear, setFilterYear] = useState(2024);
    const [filterMonth, setFilterMonth] = useState('');
    const [filterWeekData, setFilterWeekData] = useState([]);
    const [filterSelectedWeek, setFilterSelectedWeek] = useState('');
    const [filterCurrentWeekIndex, setFilterCurrentWeekIndex] = useState(0); // New state to track the current week index

    const currentYear = new Date().getFullYear();
    const filterYears = [];
    for (let y = 2019; y <= currentYear; y++) {
        filterYears.push(y);
    }

    const filterMonths = [
        { name: 'January', value: '01' },
        { name: 'February', value: '02' },
        { name: 'March', value: '03' },
        { name: 'April', value: '04' },
        { name: 'May', value: '05' },
        { name: 'June', value: '06' },
        { name: 'July', value: '07' },
        { name: 'August', value: '08' },
        { name: 'September', value: '09' },
        { name: 'October', value: '10' },
        { name: 'November', value: '11' },
        { name: 'December', value: '12' },
    ];

    const filterHandleYearChange = (e) => {
        setFilterYear(e.target.value);
        setFilterMonth('');
        setFilterWeekData([]);
        setFilterSelectedWeek('');
        setFilterCurrentWeekIndex(0); // Reset the week index when the year changes
    };

    const filterHandleMonthChange = (e) => {
        setFilterMonth(e.target.value);
        setFilterWeekData(filterCalculateFinancialYearWeeks(filterYear, e.target.value));
        setFilterSelectedWeek('');
        setFilterCurrentWeekIndex(0); // Reset the week index when the month changes
    };

    const filterHandleWeekChange = (e) => {
        setFilterSelectedWeek(e.target.value);
        // const weekIndex = filterWeekData.findIndex((week) => week.week === parseInt(e.target.value));
        // setFilterCurrentWeekIndex(weekIndex);
    };

    // Function to navigate to the previous week with "filter" prefix
    const filterNavigateWeek = (direction) => {
        let newIndex = filterCurrentWeekIndex + direction;

        if (newIndex < 0) newIndex = 0; // Prevent going below 0
        if (newIndex >= filterWeekData.length) newIndex = filterWeekData.length - 1; // Prevent going above the total weeks

        setFilterCurrentWeekIndex(newIndex);
        setFilterSelectedWeek(filterWeekData[newIndex].week);
    };

    // Get the previous year for displaying months
    //const filterPreviousYear = currentYear - 1;

    const getFinancialYearMonths = (year) => {
        // If year is between 2024 and 2025, show months for both 2024 and 2025
        if (year === '2024-2025') {
            return [
                { name: 'April', value: '04' },
                { name: 'May', value: '05' },
                { name: 'June', value: '06' },
                { name: 'July', value: '07' },
                { name: 'August', value: '08' },
                { name: 'September', value: '09' },
                { name: 'October', value: '10' },
                { name: 'November', value: '11' },
                { name: 'December', value: '12' },
                { name: 'January', value: '01' },
                { name: 'February', value: '02' },
                { name: 'March', value: '03' },
            ];
        }
        // Otherwise, show months for the selected year only
        return filterMonths.filter((m) => m.value >= '04');
    };

 
    function filter_btn() {
        const selectedWeekNumber = parseInt(document.getElementsByClassName("get_week_value")[0]?.value, 10);
    
        if (!selectedWeekNumber || isNaN(selectedWeekNumber)) {
            console.error("Invalid or no week number selected.");
            return;
        }
    
        // Calculate the start date of the financial year
        const financialYearStart = new Date(`${filterYear}-04-01`);
        const weekStartDate = addDays(financialYearStart, (selectedWeekNumber - 1) * 7);
        const weekEndDate = addDays(weekStartDate, 6);
    
        console.log("Week Number: " + selectedWeekNumber);
        console.log("Week Start Date: " + weekStartDate.toISOString().split("T")[0]);
        console.log("Week End Date: " + weekEndDate.toISOString().split("T")[0]);
    
        // You can set the current week state here if needed
        setCurrentWeekStart(weekStartDate);
    
        // Perform any further actions with the selected week number, start, and end dates
    }
    
    

    return (
        <div className="main-content">
     
        <div className="page-content">
          <div className="container-fluid">
  
        <div className="App">
            <h1>Financial Year Week Numbers</h1>

            <div>
                <label>Select Year: </label>
                <select value={filterYear} onChange={filterHandleYearChange}>
                    {filterYears.map((y) => (
                        <option key={y} value={y}>
                            {y}-{y + 1}
                        </option>
                    ))}
                   
                </select>
            </div>

            <div>
                <label>Select Month: </label>
                <select value={filterMonth} onChange={filterHandleMonthChange} disabled={!filterYear}>
                    <option value="">Select Month</option>
                    {getFinancialYearMonths(filterYear).map((m) => (
                        <option key={m.value} value={m.value}>
                            {filterYear}-{m.value} {m.name}
                        </option>
                    ))}
                </select>
            </div>

            {filterWeekData.length > 0 && (
                <div>
                    <label>Select Week: </label>
                    <select
                        className="get_week_value"
                        value={filterSelectedWeek}
                        onChange={filterHandleWeekChange}
                        disabled={!filterMonth}
                    >
                        <option value="">Select Week</option>
                        {filterWeekData.map((week, index) => (
                            <option key={index} value={week.week}>
                                Week {week.week} ({week.startDate} - {week.endDate})
                            </option>
                        ))}
                    </select>

                </div>
            )}

            {filterWeekData.length > 0 && (
                <div>
                    <button onClick={() => filterNavigateWeek(-1)} disabled={filterCurrentWeekIndex === 0}>
                        Previous Week
                    </button>
                    <button onClick={() => filterNavigateWeek(1)} disabled={filterCurrentWeekIndex === filterWeekData.length - 1}>
                        Next Week
                    </button>
                </div>
            )}
            <button type="button" onClick={() => filter_btn()} className="btn btn-sm btn-primary">Search</button>

            {/* {filterSelectedWeek && (
                <div>
                    <h2>Selected Week: {filterSelectedWeek}</h2>
                    <p>Week Number: {filterSelectedWeek}</p>
                    <p>Start Date: {filterWeekData.find((week) => week.week === parseInt(filterSelectedWeek)).startDate}</p>
                    <p>End Date: {filterWeekData.find((week) => week.week === parseInt(filterSelectedWeek)).endDate}</p>
                </div>
            )} */}
             <div  style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
              
              <button className="btn btn-success prev_week btn_navigation" onClick={() => navigateWeek("prev")}>
                &lt; Prev
              </button>
              <h3 style={{ margin: "0" }}>
                {format(currentWeekStart, "MMMM yyyy") } - Week {financialWeek}
              </h3>

             
              <button className="btn btn-success next_week btn_navigation" onClick={() => navigateWeek("next")}>
                Next &gt;
              </button>

             

            </div>
          {isLoading ? (
              <div>Loading...</div>
            ) : (
              <table className="table table-stripped table-bordered week_table" id="week_table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>{/* Project Name is shown in the select box */}</th>
                    {weekDates.map((date, index) => (
                      <th key={index}>{format(date, "EEE, dd MMM")}</th>
                    ))}
                    <th>Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                 
                </tbody>

              </table>
            )}
        </div>
          </div>
      </div>
    </div>
    );
};

export default App;
