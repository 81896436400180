
import React, { useState, useEffect } from 'react';
import axios from "axios";

const Dashboard = () =>{
    const [greeting, setGreeting] = useState('');
    const full_name = localStorage.getItem("full_name");
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [employees, setEmployees] = useState([]);
    //const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
            setGreeting('Good Morning');
        } else if (currentHour < 18) {
            setGreeting('Good Afternoon');
        } else {
            setGreeting('Good Evening');
        }
    }, []);

    useEffect(() => {
        const fetchdata = async () => {
            try {
                const formData1 = new FormData();
                formData1.append("user_id", localStorage.getItem("mst_users_id"));

                const response = await axios({
                    method: 'POST',
                    url: `${API_BASE_URL}/Users/get_users_list`,
                    data: formData1,
                    headers: { "Content-Type": "multipart/form-data" }
                });

                const userData = response.data.results[0];
                setEmployees(userData);
            } catch (error) {
                console.error('Error fetching user data:', error);
              
            }
         
        };

        fetchdata();
    }, [API_BASE_URL]);


    return(
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    {/* <!-- start page title --> */}
                    <div className="row mb-3 pb-1 ">
                        <div className="col-12">
                            <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                                <div className="flex-grow-1">
                                    <h4 className="fs-16 mb-1">{greeting}, {full_name}!</h4>
                                   
                                </div>
                                <div className="mt-3 mt-lg-0">                                    
                                </div>
                            </div>
                            {/* <!-- end card header --> */}
                        </div>                        
                    </div>
                     <div className="row">
                       
                        <div className="col-xxl-3 col-md-6">
                            <div>
                                <label for="basiInput" className="form-label">User Name</label>
                                <input type="text" className="form-control"  value={employees.employee_name} readOnly id="basiInput"/>
                            </div>
                        </div>
                        
                        <div className="col-xxl-3 col-md-6">
                            <div>
                                <label for="basiInput" className="form-label">Email Id</label>
                                <input type="text" className="form-control" value={employees.employee_email} readOnly id="basiInput"/>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-md-6">
                            <div>
                                <label for="basiInput" className="form-label">Role Name</label>
                                <input type="text" className="form-control" value={employees.role_name} readOnly  id="basiInput"/>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-md-6">
                            <div>
                                <label for="basiInput" className="form-label">Department Name</label>
                                <input type="text" className="form-control" value={employees.department_name} readOnly id="basiInput"/>
                            </div>
                        </div>
                    </div> 
                    {/* <!-- end page title --> */}
                </div>
                {/* <!-- container-fluid --> */}
            </div>
            {/* <!-- End Page-content --> */}
            
        </div>
            
    )
}

export default Dashboard;