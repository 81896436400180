import React, { useState, useEffect } from 'react';
import axios from "axios";

const Home = () => {
    const [greeting, setGreeting] = useState('');
    const full_name = localStorage.getItem("full_name");
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
            setGreeting('Good Morning');
        } else if (currentHour < 18) {
            setGreeting('Good Afternoon');
        } else {
            setGreeting('Good Evening');
        }
    }, []);

    useEffect(() => {
        const fetchdata = async () => {
            try {
                const formData1 = new FormData();
                formData1.append("user_id", localStorage.getItem("mst_users_id"));

                const response = await axios({
                    method: 'POST',
                    url: `${API_BASE_URL}/Users/get_delegate_user_list`,
                    data: formData1,
                    headers: { "Content-Type": "multipart/form-data" }
                });

                console.log(response.data);
                setEmployees(response.data.results || []); // Use `results` from the API response

            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchdata();
    }, [API_BASE_URL]);

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    {/* Page Title */}
                    <div className="row mb-3 pb-1">
                        <div className="col-12">
                            <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                                <div className="flex-grow-1">
                                    <h4 className="fs-16 mb-1">{greeting}, {full_name}!</h4>
                                  
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Display Employee Cards */}
                    {localStorage.getItem("mst_roles_id") === "4" && (
                        <div className="row">
                            {employees.map((employee) => (
                                <a 
                                    href={`/MainPage?emp_id=${employee.mst_users_id}`} 
                                    key={employee.mst_users_id} 
                                    className="col-xl-3 col-md-6 text-decoration-none"
                                >
                                    <div className="card card-animate">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <p className="fw-medium text-muted mb-0">Employee</p>
                                                    <h6 className="fw-bold mt-2">{employee.employee_name}</h6>
                                                </div>
                                                <div>
                                                    <div className="avatar-sm flex-shrink-0">
                                                        <span className="avatar-title bg-info-subtle rounded-circle fs-2">
                                                            <i className="text-info fa fa-user material-shadow"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default Home;
