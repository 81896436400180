import React, { useState, useEffect } from "react";
import 'jquery-validation';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import axios from "axios";
import 'react-datepicker/dist/react-datepicker.css';

import { MultiSelect } from 'primereact/multiselect';
import DatePicker from 'react-datepicker';  // Import the date picker
import 'react-datepicker/dist/react-datepicker.css';  // Import styles for the date picker

const Role = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [fromDate, setFromDate] = useState(null); // State for From Date
    const [toDate, setToDate] = useState(null);     // State for To Date


    const handleEmployeeChange = (e) => {
        setSelectedEmployees(e.value);
        console.log('Selected Employee:', e.value);
    };
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const fetchData = async (page, perPage) => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("mst_users_id", localStorage.getItem('mst_users_id'));

            const response = await axios.post(
                `${API_BASE_URL}/Logreport/get_department_master_logs`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    params: {
                        page: page,
                        per_page: perPage
                    }
                }
            );

            setData(response.data.results);
            setTotalRows(response.data.total);
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error("Failed to load data");
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        const fetchProjectAndUsers = async () => {
            try {
                const [userResponse] = await Promise.all([
                    axios.post(`${API_BASE_URL}/Users/get_users_list`),                   
                ]);
    
                const usersData = userResponse.data?.results || [];
               
                // Map employee options
                const employeesOptions = usersData.map(emp => ({
                    name: emp.employee_name,
                    value: emp.mst_users_id,
                }));
                setEmployeeOptions(employeesOptions);
    
                // Map department options
               
    
                console.log("Department IDs:", usersData.map(dep => dep.mst_users_id));
            } catch (error) {
                console.error('Error fetching projects or users:', error);
                toast.error('Error fetching projects or users');
            }
        };
        fetchProjectAndUsers();
    }, [API_BASE_URL]);
    

    useEffect(() => {
        fetchData(currentPage, perPage);
    }, [currentPage, perPage]);

    const handlePageChange = page => {
        setCurrentPage(page);
        fetchData(page, perPage); // Fetch data based on new page
    };

    const handleRowsPerPageChange = newPerPage => {
        setPerPage(newPerPage);
        setCurrentPage(1); // Reset to first page
        fetchData(1, newPerPage); // Fetch data with new page size
    };

    const columns = [
        { name: 'SL.No.', selector: row => row.count, minWidth: '100px' },
        { name: 'Module ID', selector: row => row.module_id, minWidth: '100px' },
        { name: 'Module', selector: row => row.module, minWidth: '150px' },
        { name: 'Action', selector: row => row.action, minWidth: '100px' },
        { name: 'Date Time', selector: row => row.create_date, minWidth: '150px' },
        {
            name: 'Old Data',
            cell: row => (
                <div
                    dangerouslySetInnerHTML={{ __html: row.old_data }}
                    style={{
                        whiteSpace: 'nowrap',
                        padding: '0px',
                        display: 'inline-block',
                    }}
                />
            ),
            minWidth: '300px',
        },
        {
            name: 'New Data',
            cell: row => (
                <div
                    dangerouslySetInnerHTML={{ __html: row.new_data }}
                    style={{
                        whiteSpace: 'nowrap',
                        padding: '0px',
                        display: 'inline-block',
                    }}
                />
            ),
            minWidth: '300px',
        },
        { name: 'Changed By', selector: row => row.changed_by, minWidth: '150px' },
    ];

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#0f6839',
                color: 'white',
            },
        },
        headCells: {
            style: {
                color: 'white',
                fontWeight: 'bold',
                padding: '10px',
            },
        },
        pagination: { style: { padding: '10px' } },
        cells: {
            style: {
                padding: '10px',
                whiteSpace: 'nowrap',
                display: 'inline-block',
            },
        },
    };

   
 
    const searchTable = async(page, perPage)=>{
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('employee_id', document.getElementsByClassName("employee_id")[0]?.value || '');
           
            formData.append('from_date', document.getElementsByClassName("from_date")[0]?.value || '');
            formData.append('to_date', document.getElementsByClassName("to_date")[0]?.value || '');

            const response = await axios.post(
                `${API_BASE_URL}/Logreport/get_all_department_master_logs_search`,
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                    params: { page, per_page: perPage }
                }
                
            );
            setData(response.data.results);
            setTotalRows(response.data.total);
            setLoading(false);
          
        } catch (error) {
            console.error('Error exporting filtered data:', error);
            toast.error('Failed to Search data');
        }
    }

    const exportExcelFiltered = async (page, perPage) => {
        try {
            const formData = new FormData();
            formData.append('employee_id', document.getElementsByClassName("employee_id")[0]?.value || '');
            formData.append('from_date', document.getElementsByClassName("from_date")[0]?.value || '');
            formData.append('to_date', document.getElementsByClassName("to_date")[0]?.value || '');
    
            const response = await axios.post(
                `${API_BASE_URL}/Logreport/export_excel_department_master_log`,
                formData,
                
                { responseType: 'blob' },
                {
                    headers: { "Content-Type": "multipart/form-data" },
                    params: { page, per_page: perPage }
                } // Ensures file download
            );
    
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'department_masters_logs.csv');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error exporting filtered data:', error);
            toast.error('Failed to export data');
        }
    };
    

    return (
        <div className="main-content">
            <div className="page-content">

                <div className="row">                                        
                    <div className="col-md-2">
                        <label className="form-label">Changed By</label>
                        
                        <MultiSelect 
                            value={selectedEmployees}
                            onChange={handleEmployeeChange}
                            options={employeeOptions} 
                            optionLabel="name" 
                            filter 
                            placeholder="Select Changed By" 
                            maxSelectedLabels={3} 
                            className="w-full md:w-30rem" 
                        />
                            <input type="hidden" className="employee_id" value={selectedEmployees}/>
                    </div>
                    <div className="col-md-2">
                        <label className="form-label">From Date</label>
                        <DatePicker
                            selected={fromDate}
                            onChange={date => setFromDate(date)}
                            dateFormat="dd-MM-yyyy"
                            isClearable
                            className="form-control from_date"
                            placeholderText="Select From Date "
                        />
                    </div>
                    <div className="col-md-2">
                        <label className="form-label">To Date</label>
                        <DatePicker
                            selected={toDate}
                            onChange={date => setToDate(date)}
                            dateFormat="dd-MM-yyyy"
                            isClearable
                            className="form-control to_date"
                            placeholderText="Select To Date "
                        />
                    </div>
                    <div className="col-md-2">
                    <button className="btn btn-success text-right mt-4" onClick={searchTable}>Search</button>
                    </div>
                </div>

                <div className="row mt-2">

                    <div className="col-md-6 text-left">                                                    
                        <button className="btn btn-primary" onClick={exportExcelFiltered}>Export All</button>
                    </div>
                </div>
                <div className="row mt-2">

                    <div className="container-fluid" style={{ overflowX: 'auto', width: '100%' }}>
                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationPerPage={perPage}
                            paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                            customStyles={customStyles}
                            style={{
                                tableLayout: 'auto',
                                width: '100%',
                            }}
                        />
                    </div>

                </div>

            </div>
        </div>
    );
};

export default Role;
