import React, { useState, useEffect, useRef } from "react";
import $ from 'jquery'; // Import jQuery
import 'jquery-validation'; // Import jQuery Validation Plugin
import { toast } from 'react-toastify';
import axios from "axios";

const EmailSetting = () => {
    const [formData, setFormData] = useState({ email_setting: '' });
    const [loading, setLoading] = useState(true);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const formRef = useRef(null);

    // Email regex to validate comma-separated email ids
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(,[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;

    // Fetch data when the component mounts
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/email/get_email_setting`);
            if (response.data && response.data.results && response.data.results.length > 0) {
                const emailSetting = response.data.results[0]; 
                setFormData({
                    email_setting: emailSetting.email_setting || '',
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(); // Fetch the email settings data when component mounts

        // Initialize jQuery validation
        $(formRef.current).validate({
            rules: {
                email_setting: {
                    required: true,
                    pattern: emailRegex // Add regex pattern rule here
                },
            },
            messages: {
                email_setting: {
                    required: "Please enter email ids",
                    pattern: "Please enter valid comma-separated email ids"
                },
            },
            submitHandler: function () {
                handleSubmit(); // Trigger handleSubmit when validation passes
            }
        });
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        
        // Validate comma-separated emails
        if (!emailRegex.test(formData.email_setting)) {
            toast.error("Please enter valid comma-separated email ids.");
            return;
        }

        try {
            const formData1 = new FormData();
            formData1.append("email_setting", formData.email_setting); // Use the state value directly

            let apiEndpoint = `${API_BASE_URL}/email/email_setting_add`;
            const response = await axios.post(apiEndpoint, formData1, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });

            if (response.data.success === 1) {
                toast.success(response.data.message);
                await fetchData(); // Call fetchData to refresh the email settings after submission
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during submission:', error);
            toast.error("An error occurred while updating email settings.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    if (loading) {
        return <div>Loading...</div>; // Optional loading indicator
    }

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent">
                                <h4 className="mb-sm-0">Email Settings</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">Email Settings</a></li>
                                        <li className="breadcrumb-item active">Add Emails</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-6">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Add Email</h4>
                                </div>
                                <div className="card-body">
                                    <p className="text-muted">Fields marked with an asterisk (*) are compulsory.</p>
                                    <div className="live-preview">
                                        <form ref={formRef} id="myForm" onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="email_setting" className="form-label">Email Ids<span>*</span></label>
                                                        <textarea
                                                            id="email_setting"
                                                            name="email_setting"
                                                            className="form-control"
                                                            value={formData.email_setting} // Correctly bind the textarea value
                                                            onChange={handleChange}
                                                            placeholder="Enter Email Ids with comma separated"
                                                            rows="4"
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailSetting;
