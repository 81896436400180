
import React, { useState, useEffect } from 'react';

const Dashboard = () =>{
    const [greeting, setGreeting] = useState('');
    const full_name = localStorage.getItem("full_name");

    //const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
            setGreeting('Good Morning');
        } else if (currentHour < 18) {
            setGreeting('Good Afternoon');
        } else {
            setGreeting('Good Evening');
        }
    }, []);

    return(
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    {/* <!-- start page title --> */}
                    <div className="row mb-3 pb-1 ">
                        <div className="col-12">
                            <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                                <div className="flex-grow-1">
                                    <h4 className="fs-16 mb-1">{greeting}, {full_name}!</h4>
                                    <p className="text-muted mb-0">Technology is the key to my freedom.</p>
                                </div>
                                <div className="mt-3 mt-lg-0">                                    
                                </div>
                            </div>
                            {/* <!-- end card header --> */}
                        </div>                        
                    </div>
                     <div className="row">
                        <div className="col-xl-12" style={{ textAlign: 'center'}} >
                            {/* <div className="card crm-widget">
                                <div className="card-body p-0">
                                    <div className="row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
                                        <div className="col">
                                            <div className="py-4 px-3">
                                                <h5 className="text-muted text-uppercase fs-13">Users <i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className=" ri-user-line display-6 text-muted cfs-22"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h2 className="mb-0 cfs-22"><span className="counter-value" data-target="197">197</span></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col">
                                            <div className="mt-3 mt-md-0 py-4 px-3">
                                                <h5 className="text-muted text-uppercase fs-13">Projects <i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className=" ri-list-check-2 display-6 text-muted cfs-22"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h2 className="mb-0 cfs-22"><span className="counter-value" data-target="489.4">12</span></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col">
                                            <div className="mt-3 mt-md-0 py-4 px-3">
                                                <h5 className="text-muted text-uppercase fs-13">Worked Today <i className="ri-arrow-down-circle-line text-danger fs-18 float-end align-middle"></i></h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className=" ri-time-line display-6 text-muted cfs-22"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h2 className="mb-0 cfs-22"><span className="counter-value" data-target="32.89">32.89</span></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col">
                                            <div className="mt-3 mt-lg-0 py-4 px-3">
                                                <h5 className="text-muted text-uppercase fs-13">Worked This Week <i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className="ri-time-line display-6 text-muted cfs-22"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h2 className="mb-0 cfs-22"><span className="counter-value" data-target="1596.5">500</span></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col">
                                            <div className="mt-3 mt-lg-0 py-4 px-3">
                                                <h5 className="text-muted text-uppercase fs-13">Worked This Month <i className="ri-arrow-down-circle-line text-danger fs-18 float-end align-middle"></i></h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className="ri-time-line display-6 text-muted cfs-22"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h2 className="mb-0 cfs-22"><span className="counter-value" data-target="2659">180</span></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                               
                            </div> */}

                            <h2 style={{textAlign: "center"}}>Dashboard Under Development</h2>

                            <img style={{ height: '50vh' }} src="/dashboard_img.png" alt="Dashboard" />
                            
                        </div>
                        
                    </div> 
                    {/* <!-- end page title --> */}
                </div>
                {/* <!-- container-fluid --> */}
            </div>
            {/* <!-- End Page-content --> */}
            
        </div>
            
    )
}

export default Dashboard;