import { useEffect, useState } from "react";
import axios from "axios";
import Select from 'react-select';
import {useLocation} from 'react-router-dom'

const Dashboard = () => {
    const location = useLocation();
    const useQuery = () => {
        return new URLSearchParams(location.search);
    };

    const query = useQuery();
    const id = query.get("emp_id");

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [timesheets, setTimesheets] = useState([]);
    const statusOptions = [
        { value: '0', label: 'Draft' },
        { value: '1', label: 'Submitted' },
        { value: '2', label: 'Approved' }
    ];
    const [status, setStatus] = useState(statusOptions[0]);

    useEffect(() => {
        const fetchdata = async () => {
            const formData1 = new FormData();
            formData1.append("user_id", id); // Add your user_id here
            formData1.append("status", status.value);

            try {
                const response = await axios({
                    method: "POST",
                    url: `${API_BASE_URL}/Timesheet/get_all_timesheet_by_status`,
                    data: formData1,
                    headers: { "Content-Type": "multipart/form-data" },
                });

                if (response.data.success) {
                    setTimesheets(response.data.data); // Set fetched data
                }
            } catch (error) {
                console.error("Error fetching timesheet data:", error);
            }
        };

        fetchdata();
    }, [status, API_BASE_URL]);

    const handleStatusChange = (selectedOption) => {
        setStatus(selectedOption);
    };

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row mb-3 pb-1">
                        <div className="col-12">
                            <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                                <div className="flex-grow-1">
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12" style={{ textAlign: 'left' }}>
                            <div className="card">
                                <div className="card-header align-items-left d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Reports</h4>
                                    <div className="flex-shrink-0">
                                        <Select
                                            options={statusOptions}
                                            value={status}
                                            onChange={handleStatusChange}
                                        />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='col-lg-12'>
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-striped mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">SL.No.</th>
                                                        <th scope="col">Project</th>
                                                        <th scope="col">Week No.</th>
                                                        <th scope="col">Year</th>
                                                        <th scope="col">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {timesheets.map((timesheet, index) => (
                                                    <tr key={timesheet.mst_timesheets_id}>
                                                        <td>{index + 1}</td>
                                                        <td>{timesheet.mst_projects_id}</td>
                                                        <td>{timesheet.week_no}</td>
                                                        <td>{timesheet.year}</td>
                                                        <td>
                                                            {timesheet.status === '0' && <span className="badge bg-secondary">Draft</span>}
                                                            {timesheet.status === '1' && <span className="badge bg-primary">Submitted</span>}
                                                            {timesheet.status === '2' && <span className="badge bg-success">Approved</span>}
                                                        </td>
                                                    </tr>
                                                ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
