import React, { useState, useEffect, useRef } from "react";
import $ from 'jquery'; // Import jQuery
import 'jquery-validation'; // Import jQuery Validation Plugin
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import axios from "axios";

const Department = () => {
    const [formData, setFormData] = useState({ department_code: '', department_name: '', mst_departments_id: '' });
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
   // const jwtToken = localStorage.getItem('token');

    const formRef = useRef(null);

    useEffect(() => {
        $(formRef.current).validate({
            rules: {
                department_code: {
                    required: true,
                    minlength: 3
                },
                department_name: {
                    required: true,
                }
            },
            messages: {
                department_code: {
                    required: "Please enter department code",
                    minlength: "Department code should be at least 3 characters long"
                },
                department_name: {
                    required: "Please enter department name",
                }
            },
            submitHandler: function () {
                handleSubmit();
            }
        });
    }, []);

    const handleSubmit = async () => {
       
        try {
            const formData1 = new FormData();
            formData1.append("department_code", formRef.current.department_code.value);
            formData1.append("department_name", formRef.current.department_name.value);
            formData1.append("mst_departments_id", formRef.current.mst_departments_id.value);
            formData1.append("user_id", localStorage.getItem("mst_users_id") || "");
          
            let apiEndpoint = `${API_BASE_URL}/Department/add`;
            let method = 'post';

         
            const response = await axios({
                method: method,
                url: apiEndpoint,
                data: formData1,
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });

            if (response.data.success === 1) {
                toast.success(response.data.message);
                fetchData(currentPage, perPage); // Reload data after successful submission
            } else {
                toast.error(response.data.message);
            }

            
            setFormData({ department_code: '', department_name: '', mst_departments_id: '' });
        } catch (error) {
            console.error('Error during submission:', error);
        }
    };

    const fetchData = async (page, perPage) => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/Department/get_departments`, {
                params: {
                    page: page,
                    per_page: perPage
                }
            });
            setData(response.data.results); // Modify based on your API structure
            setTotalRows(response.data.total); // Assume the API returns total records
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(currentPage, perPage);
    }, [currentPage, perPage]);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    const handleEdit = (row) => {
        setFormData({
            department_code: row.department_code,
            department_name: row.department_name,
            mst_departments_id: row.mst_departments_id // Ensure this ID is correctly populated
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDelete = async (row) => {
        if (window.confirm(`Are you sure you want to delete department : ${row.department_name}?`)) {
            try {
                await axios.delete(`${API_BASE_URL}/Department/delete/${row.mst_departments_id}`,{
                    headers: {
                        // 'Authorization': 'Bearer YOUR_TOKEN_HERE',  // Replace with your actual token or authentication method
                        // 'Content-Type': 'application/json'          // You can add other headers as needed
                        "Access-Control-Allow-Origin": "*",
                      }
                });
                fetchData(currentPage, perPage);
            } catch (error) {
                console.error('Error deleting department:', error);
            }
        }
    };

    const columns = [ {
        name: 'SL.No.',
        selector: row => row.count,
        sortable: false,
    },{
        name: 'Department Code',
        selector: row => row.department_code,
        sortable: false,
    },
    {
        name: 'Department Name',
        selector: row => row.department_name,
        sortable: false,
    },
    {
        name: 'Action',
        selector: row => (
            <div className="btn-group btn-group-sm material-shadow mt-2" role="group">
                <button onClick={() => handleEdit(row)} className="btn btn-primary material-shadow-none">
                    <i className="ri-edit-line"></i>
                </button>
                <button onClick={() => handleDelete(row)} className="btn btn-danger material-shadow-none">
                    <i className="ri-delete-bin-2-line"></i>
                </button>
            </div>
        ),
        sortable: false,
    }
    ];

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent">
                                <h4 className="mb-sm-0">Departments </h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">Masters</a></li>
                                        <li className="breadcrumb-item active">Departments</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Add Department</h4>                                    
                                </div>
                                <div className="card-body">
                                    <p className="text-muted">Fields marked with an asterisk (*) are compulsory.</p>
                                    <div className="live-preview">
                                        <form ref={formRef} id="myForm" onSubmit={handleSubmit}>
                                            <input type="hidden" id="mst_departments_id" name="mst_departments_id" className="form-control" value={formData.mst_departments_id} onChange={handleChange} placeholder="" />
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="department_code" className="form-label">Department Code <span>*</span></label>
                                                        <input type="text" id="department_code" name="department_code" className="form-control" value={formData.department_code} onChange={handleChange} placeholder="Department Code" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="department_name" className="form-label">Department Name <span>*</span></label>
                                                        <input type="text" id="department_name" name="department_name" className="form-control" value={formData.department_name} onChange={handleChange} placeholder="Department Name" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-8">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Departments</h4>
                                </div>
                                <div className="card-body">
                                    <div className="live-preview">
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                            customStyles={{
                                                headRow: {
                                                    style: {
                                                        backgroundColor: '#0f6839', // Dark Green background
                                                        color: 'white', // White text
                                                    },
                                                },
                                                headCells: {
                                                    style: {
                                                        color: 'white', // White text for the header cells
                                                        fontWeight: 'bold', // Bold text for header cells
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Department;
