import {Link} from 'react-router-dom'
const NotFound = () =>{
    return(
        <div className="auth-page-content overflow-hidden pt-lg-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-5">
                        <div className="card overflow-hidden card-bg-fill galaxy-border-none">
                            <div className="card-body p-4">
                                <div className="text-center">
                                    <lord-icon className="avatar-xl" src="https://cdn.lordicon.com/etwtznjn.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c"></lord-icon>
                                    <h1 className="text-primary mb-4">Oops !</h1>
                                    <h4 className="text-uppercase">Sorry, Page not Found 😭</h4>
                                    <p className="text-muted mb-4">The page you are looking for not available!</p>
                                    <Link to="/dashboard" className="btn btn-success"><i className="mdi mdi-home me-1"></i>Back to home</Link>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end card --> */}
                    </div>
                    {/* <!-- end col --> */}

                </div>
                {/* <!-- end row --> */}
            </div>
            {/* <!-- end container --> */}
        </div>
    )
}

export default NotFound;