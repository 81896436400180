import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';

import $ from 'jquery';
import 'jquery-validation';
import { toast } from 'react-toastify';
import axios from "axios";
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
//import Select from 'react-select';


const EditUser = () => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    const query = useQuery();

    const id = query.get('id');
    console.log(id);
    const [userToEdit, setUserToEdit] = useState(null);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [formData, setFormData] = useState({
        employee_id: '',
        employee_name: '',
        mst_roles_id: '',
        mst_departments_id: '',
        employee_email: '',
        employee_mobile: '',
        date_of_birth: '',
        date_of_joining: '',
        address: '',
        password: '',
        confirmPassword: '',
        delegate_employees: '',
        status: '',
    });
    
    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [showMultiSelect, setShowMultiSelect] = useState(false);
    const formRef = useRef(null);

    

    useEffect(() => {
        const fetchdata = async () => {
            
            console.log(id);
            if (id) {
                try {
                    
                    
                    const formData1 = new FormData();
                    formData1.append("user_id", id);

                    const response = await axios({
                        method: 'POST',
                        url: `${API_BASE_URL}/Users/get_users_list`,
                        data: formData1,
                        headers: { "Content-Type": "multipart/form-data" }
                    });
                    

                    const userData = response.data.results[0];
                    
                    setUserToEdit(userData);
                    console.log("userData "+userData.status);
                    setShowMultiSelect(userData.mst_roles_id === '4');
                    
                    setFormData({
                        employee_id: userData.employee_id || '',
                        mst_users_id: userData.mst_users_id || '',
                        employee_name: userData.employee_name || '',
                        mst_roles_id: userData.mst_roles_id || '',
                        mst_departments_id: userData.mst_departments_id || '',
                        employee_email: userData.employee_email || '',
                        employee_mobile: userData.employee_mobile || '',
                        date_of_birth: userData.date_of_birth || '',
                        date_of_joining: userData.date_of_joining || '',
                        address: userData.address || '',
                        password: '',  // Password left blank for security
                        confirmPassword: '',
                        delegate_employees: userData.delegate_employees || '',
                        status: userData.status || '',
                    });

                   
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    toast.error('Error fetching user data');
                }
            }
        };

        fetchdata();
    }, [API_BASE_URL, id]);

    useEffect(() => {
        const fetchDepartmentsAndRoles = async () => {
            try {
                const [departmentsResponse, rolesResponse, employeeResponse] = await Promise.all([
                    axios.get(`${API_BASE_URL}/Department/get_all`),
                    axios.get(`${API_BASE_URL}/Role/get_all`),
                    axios.get(`${API_BASE_URL}/Users/get_employees`)
                ]);

                setDepartments(departmentsResponse.data.data);
                setRoles(rolesResponse.data.data);
                setEmployees(employeeResponse.data);
            } catch (error) {
                console.error('Error fetching departments or roles:', error);
                toast.error('Error fetching departments or roles');
            }
        };

        fetchDepartmentsAndRoles();
    }, [API_BASE_URL]);

   
   
    

    useEffect(() => {
        $(formRef.current).validate({
            rules: {
                employee_id: { required: true, minlength: 3 },
                employee_name: { required: true },
                mst_departments_id: { required: true },
                mst_roles_id: { required: true },
                employee_email: { required: true, email: true },
                employee_mobile: { required: true, digits: true, minlength: 10, maxlength: 15 },
                delegate_employees: { required: true },
                password: { required: false, minlength: 6 },
                //confirmPassword: { required: true, equalTo: '#password' }
            },
            submitHandler: function () {
                handleSubmit();
            }
        });
    }, [userToEdit]);

  
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            const formData1 = new FormData();
            formData1.append("employee_id", formData.employee_id || "");
            formData1.append("employee_name", formData.employee_name || "");
            formData1.append("employee_email", formData.employee_email || "");
            formData1.append("employee_mobile", formData.employee_mobile || "");
            formData1.append("mst_departments_id", formData.mst_departments_id || "");
            formData1.append("mst_roles_id", formData.mst_roles_id || "");
            formData1.append("password", formData.password || "");
            formData1.append("confirmPassword", formData.confirmPassword || "");
            formData1.append("date_of_birth", formData.date_of_birth || "");
            formData1.append("date_of_joining", formData.date_of_joining || "");
            formData1.append("address", formData.address || "");
            formData1.append("mst_users_id", formData.mst_users_id || "");
            formData1.append("user_id", localStorage.getItem("mst_users_id") || "");
            formData1.append("status", document.getElementsByClassName("status")[0]?.value || "");
            formData1.append("delegate_employees", selectedEmployees || "");
    
            const apiEndpoint = userToEdit ? `${API_BASE_URL}/Users/add` : `${API_BASE_URL}/Users/add`;
            const method = userToEdit ? 'POST' : 'POST';
    
            const response = await axios({
                method: method,
                url: apiEndpoint,
                data: formData1,
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    
            if (response.data.success == 1) {
                toast.success(response.data.message);            
              
                localStorage.setItem('toastMessage', response.data.message);
                window.location.href = '/Users';

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during submission:', error);
        }
    };
    

    const handleRoleChange = (e) => {
        const selectedRole = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            mst_roles_id: selectedRole,
        }));
        setShowMultiSelect(selectedRole === '4');
        if (selectedRole !== '4') {
            setSelectedEmployees([]);
        }
    };

    const handleEmployeeChange = (e) => {
        setSelectedEmployees(e.value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        
    };

    const [employeeOptions, setEmployeeOptions] = useState([]);
    useEffect(() => {
      
        const fetchEmployees = async () => {
            const selectedDepartmentId = formData.mst_departments_id;
            if (selectedDepartmentId) {
                const formData1 = new FormData();
                formData1.append('mst_departments_id', selectedDepartmentId);
    
                try {
                    const response = await axios({
                        method: 'POST',
                        url: `${API_BASE_URL}/Users/get_users_list_dept_wise`,
                        data: formData1,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    });
    
                     const usersData = response.data.results;
                    console.log(response.data.results);
    
                    const employeeOptions = usersData.map((emp) => ({
                        name: emp.employee_name,
                        value: emp.mst_users_id,
                    }));
                   
                    console.log(employees);
                    console.log("delegate emloyees "+formData.delegate_employees);
                    setEmployeeOptions(employeeOptions);
                    const delegateEmployees = formData.delegate_employees;

                    // Step 1: Log the raw input
                    console.log("Step 1 - Raw delegateEmployees:", delegateEmployees);

                    let employeeIds = [];

                    // Step 2: Check if `delegateEmployees` is an array
                    if (Array.isArray(delegateEmployees)) {
                        console.log("Step 2 - delegateEmployees is an array:", delegateEmployees);

                        // Step 3: Map to strings and filter out empty values
                        employeeIds = delegateEmployees.map(id => String(id)).filter(id => id.trim() !== '');
                        console.log("Step 3 - Converted to strings and filtered:", employeeIds);

                    } else if (typeof delegateEmployees === 'string') {
                        // Step 4: Log if it's a string
                        console.log("Step 4 - delegateEmployees is a string:", delegateEmployees);

                        // Step 5: Split the string by commas, trim, and filter out empty values
                        employeeIds = delegateEmployees.split(',').map(id => id.trim()).filter(id => id !== '');
                        console.log("Step 5 - Split, trimmed, and filtered:", employeeIds);
                    }

                    // Step 6: Final list of employee IDs
                    console.log("Step 6 - Final employee IDs:", employeeIds);

                    // Set the selected employees
                    setSelectedEmployees(employeeIds);


                   

                } catch (error) {
                    console.error('Error fetching user list:', error);
                }
            }

           
        };
    
        fetchEmployees();
    }, [formData.mst_departments_id]); 

 

    const handleChangeDepartment = async (event) => {
        const selectedValue = event.target.value; // Get the selected department ID
        console.log('Selected Department ID:', selectedValue);
    
        // Update formData state with selected department ID
        setFormData({
            ...formData,
            mst_departments_id: selectedValue,
        });
    
        // Clear employees list when department changes
        setEmployees([]); // Reset employees options
    
        const formData1 = new FormData(); // Create FormData object
        formData1.append('mst_departments_id', selectedValue); // Append the selected department ID
    
        try {
            // Send selected department ID to get user list
            const response = await axios({
                method: 'POST',
                url: `${API_BASE_URL}/Users/get_users_list_dept_wise`,
                data: formData1,
                headers: { 
                    'Content-Type': 'multipart/form-data' 
                }
            });
    
            // Assuming the response contains 'results' in its data
            const usersData = response.data.results;
            console.log(response.data.results);
    
           
            
            const employeeOptions = usersData.map((emp) => ({
                name: emp.employee_name, // Accessing the correct property name
                value: emp.mst_users_id,  // Accessing the correct property name
            }));
    
          
            // Update employees state with new options
            setEmployeeOptions(employeeOptions)
    
        } catch (error) {
            console.error('Error fetching user list:', error);
        }
    };
    
    
    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent">
                                <h4 className="mb-sm-0">Edit User</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">Users</a></li>
                                        <li className="breadcrumb-item active">Edit</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="card">
                                <div className="card-header ">
                                   <div className="row">
                                        <div className="col-md-3  text-left">
                                         <h4 className="card-title mb-0 flex-grow-1">Edit User</h4>
                                        </div>
                                        <div className="col-md-9 text-right">
                                        <a href="/users" class="btn_edit">List</a>
                                        </div>
                                   </div>
                                </div>
                                <div className="card-body">
                                    <div className="live-preview">
                                        <form ref={formRef} id="myForm" onSubmit={(e) => handleSubmit(e, formData)}>
                                            <input type="hidden" id="mst_users_id" name="mst_users_id" value={formData.mst_users_id} />

                                            <div className="row">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="employee_id" className="form-label">Employee ID <span>*</span></label>
                                                    <input type="text" name="employee_id" value={formData.employee_id} onChange={handleChange} placeholder="Employee ID" className="form-control"  readOnly={!!id} style={{ backgroundColor: id ? '#ddd' : '' }} />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="employee_name" className="form-label">Employee Name <span>*</span></label>
                                                    <input type="text" name="employee_name" value={formData.employee_name} onChange={handleChange} placeholder="Employee Name" className="form-control" />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="employee_email" className="form-label">Email <span>*</span></label>
                                                    <input type="text" name="employee_email" value={formData.employee_email} onChange={handleChange} placeholder="Employee Email" className="form-control"   readOnly={!!id} style={{ backgroundColor: id ? '#ddd' : '' }}/>
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="employee_mobile" className="form-label">Mobile</label>
                                                    <input type="text" name="employee_mobile" value={formData.employee_mobile} onChange={handleChange} placeholder="Mobile" className="form-control" />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="mst_departments_id" className="form-label">Department <span>*</span></label>
                                                    <select className="form-select" name="mst_departments_id" value={formData.mst_departments_id} 
                                                    onChange={handleChangeDepartment}
                                                    >
                                                        <option value="">Select Department</option>
                                                        {departments.map(department => (
                                                            <option key={department.mst_departments_id} value={department.mst_departments_id}>{department.department_name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="mst_roles_id" className="form-label">Role <span>*</span></label>
                                                    <select className="form-select" name="mst_roles_id" value={formData.mst_roles_id} onChange={handleRoleChange}>
                                                        <option value="">Select Role</option>
                                                        {roles.map(role => (
                                                            <option key={role.mst_roles_id} value={role.mst_roles_id}>{role.role_name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {showMultiSelect && (
                                                    <div className="col-md-3 mb-3">
                                                        <label className="form-label">Select Employees <span>*</span></label>
                                                        <MultiSelect
                                                            value={selectedEmployees}
                                                            name="delegate_employees[]"
                                                            onChange={handleEmployeeChange}
                                                            options={employeeOptions}
                                                            optionLabel="name"
                                                            filter
                                                            placeholder="Select Employees"
                                                            maxSelectedLabels={3}
                                                            className="w-full md:w-30rem"
                                                        />
                                                    </div>
                                                )}
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="password" className="form-label">Password <span>*</span></label>
                                                    <input type="password" name="password" value={formData.password} onChange={handleChange} className="form-control" placeholder="Password" autoComplete="new-password" />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="confirmPassword" className="form-label">Re-type Password <span>*</span></label>
                                                    <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} className="form-control" placeholder="Re-type Password" />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="date_of_joining" className="form-label">Date Of Joining</label>
                                                    <input type="date" name="date_of_joining" value={formData.date_of_joining} onChange={handleChange} className="form-control" />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="date_of_birth" className="form-label">Date Of Birth</label>
                                                    <input type="date" name="date_of_birth" value={formData.date_of_birth} onChange={handleChange} className="form-control" />
                                                </div>
                                                {id ? (
                                                    <div className="col-md-3 mb-3">
                                                    <label htmlFor="status" className="form-label">Status</label>
                                                    <select
                                                        name="status"
                                                        className="form-select status"
                                                        value={formData.status}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="1" selected={formData.status === "1"}>Active</option>
                                                        <option value="0" selected={formData.status === "0"}>Inactive</option>
                                                    </select>
                                                    <input type="hidden" value={formData.status} />
                                                </div>
                                                
                                                ) : (
                                                    <input type="hidden" name="status" className="status" value="1" />
                                                )}
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="address" className="form-label">Address</label>
                                                    <input type="text" name="address" value={formData.address} onChange={handleChange} className="form-control" placeholder="Address" />
                                                </div>
                                                <div className="col-lg-12 text-end">
                                                    <button type="button" onClick={(e) => handleSubmit(e, formData)} className="btn btn-primary">{id ? 'Update' : 'Submit'}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditUser;
