import React, { useState, useEffect, useRef } from "react";
import $ from 'jquery'; // Import jQuery
import 'jquery-validation'; // Import jQuery Validation Plugin
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import axios from "axios";

const Role = () => {
    const [formData, setFormData] = useState({ role_code: '', role_name: '', mst_roles_id: '' });
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    //const jwtToken = localStorage.getItem('token');

    const formRef = useRef(null);

    useEffect(() => {
        $(formRef.current).validate({
            rules: {
                role_code: {
                    required: true,
                    minlength: 3
                },
                role_name: {
                    required: true,
                }
            },
            messages: {
                role_code: {
                    required: "Please enter role code",
                    minlength: "Role code should be at least 3 characters long"
                },
                role_name: {
                    required: "Please enter role name",
                }
            },
            submitHandler: function () {
                handleSubmit();
            }
        });
    }, []);

    const handleSubmit = async () => {
        try {
            const formData1 = new FormData();
            formData1.append("role_code", formRef.current.role_code.value);
            formData1.append("role_name", formRef.current.role_name.value);
            formData1.append("mst_roles_id", formRef.current.mst_roles_id.value);

            let apiEndpoint = `${API_BASE_URL}/Role/add`;
            let method = 'post';

            const response = await axios({
                method: method,
                url: apiEndpoint,
                data: formData1,
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });

            if (response.data.success === 1) {
                toast.success(response.data.message);
                fetchData(currentPage, perPage); // Reload data after successful submission
            } else {
                toast.error(response.data.message);
            }

            // Reset the form and clear the formData state
            setFormData({ role_code: '', role_name: '', mst_roles_id: '' });
        } catch (error) {
            console.error('Error during submission:', error);
        }
    };

    const fetchData = async (page, perPage) => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/Role/get_roles`, {
                params: {
                    page: page,
                    per_page: perPage
                }
            });
    
            console.log(response.data); // Log the API response to check structure
    
            setData(response.data.results);  // Make sure this matches the structure of the API response
            setTotalRows(response.data.total); // Set total number of rows for pagination
    
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };
    
    useEffect(() => {
        fetchData(currentPage, perPage);
    }, [currentPage, perPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    const handleEdit = (row) => {
        setFormData({
            role_code: row.role_code,
            role_name: row.role_name,
            mst_roles_id: row.mst_roles_id // Ensure this ID is correctly populated
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // const handleDelete = async (row) => {
    //     if (window.confirm(`Are you sure you want to delete role with ID: ${row.mst_roles_id}?`)) {
    //         try {
    //             const response = await axios({
    //                 method: 'delete',
    //                 url: `${API_BASE_URL}/Role/delete`,
    //                 data: { id: row.mst_roles_id }, // Make sure to pass 'id' in the data payload
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 },
    //             });
    
    //             if (response.data.success === 1) {
    //                 toast.success('Role deleted successfully.');
    //                 fetchData(currentPage, perPage); // Refresh data after deletion
    //             } else {
    //                 toast.error(response.data.message || 'Error deleting role.');
    //             }
    //         } catch (error) {
    //             console.error('Error deleting role:', error);
    //             toast.error('An error occurred while trying to delete the role.');
    //         }
    //     }
    // };
    
    
    const columns = [
        {
            name: 'SL.No.',
            selector: row => row.count,
            sortable: false,
        },
        {
            name: 'Role Code',
            selector: row => row.role_code,
            sortable: false,
        },
        {
            name: 'Role Name',
            selector: row => row.role_name,
            sortable: false,
        },
        {
            name: 'Action',
            cell: row => (
                <div className="btn-group btn-group-sm material-shadow mt-2" role="group">
                    <button onClick={() => handleEdit(row)} className="btn btn-primary material-shadow-none">
                        <i className="ri-edit-line"></i>
                    </button>
                    {/* <button onClick={() => handleDelete(row)} className="btn btn-danger material-shadow-none">
                        <i className="ri-delete-bin-2-line"></i>
                    </button> */}
                </div>
            ),
            sortable: false,
            
        }
    ];
   // console.log(handleDelete());
    const filteredData = data.filter(item =>
        item.role_code.toLowerCase().includes(searchText.toLowerCase()) ||
        item.role_name.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent">
                                <h4 className="mb-sm-0">Roles</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">Masters</a></li>
                                        <li className="breadcrumb-item active">Roles</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Add Role</h4>
                                </div>
                                <div className="card-body">
                                    <p className="text-muted">Fields marked with an asterisk (*) are compulsory.</p>
                                    <div className="live-preview">
                                        <form ref={formRef} id="myForm" onSubmit={handleSubmit}>
                                            <input type="hidden" id="mst_roles_id" name="mst_roles_id" className="form-control" value={formData.mst_roles_id} onChange={handleChange} placeholder="" />
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="role_code" className="form-label">Role Code<span>*</span></label>
                                                        <input type="text" id="role_code" name="role_code" className="form-control" value={formData.role_code} onChange={handleChange} placeholder="Role Code" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="role_name" className="form-label">Role Name<span>*</span></label>
                                                        <input type="text" id="role_name" name="role_name" className="form-control" value={formData.role_name} onChange={handleChange} placeholder="Role Name" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-8">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Roles</h4>
                                </div>
                                <div className="card-body">
                                    <div className="live-preview">

<div className="col-md-2" style={{ float:"right"}}>
                                    <input
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                className="form-control mb-2"
            />
            </div>

                                    <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                            customStyles={{
                                                headRow: {
                                                    style: {
                                                        backgroundColor: '#0f6839', // Dark Green background
                                                        color: 'white', // White text
                                                    },
                                                },
                                                headCells: {
                                                    style: {
                                                        color: 'white', // White text for the header cells
                                                        fontWeight: 'bold', // Bold text for header cells
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Role;
