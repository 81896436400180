import React, { useState, useEffect } from "react";
//import $ from 'jquery'; // Import jQuery
import 'jquery-validation'; // Import jQuery Validation Plugin
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import axios from "axios";
import DatePicker from 'react-datepicker';  // Import the date picker
import 'react-datepicker/dist/react-datepicker.css';  // Import styles for the date picker
//import { format } from 'date-fns';  // For formatting dates
import * as XLSX from 'xlsx';  // Import xlsx for Excel export
import { MultiSelect } from 'primereact/multiselect';
import Select from 'react-select';
const Role = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    // const [employee_name, setEmployeeName] = useState('');
    // const [project_name, setProjectName] = useState('');
    const [profit_center, setProfitCenter] = useState('');
   
    const [toemail, setToemail] = useState('');
    const [ccemail, setCCemail] = useState('');
    const [fromDate, setFromDate] = useState(null); // State for From Date
    const [toDate, setToDate] = useState(null);     // State for To Date

    const [projects, setProjects] = useState([]);
    const [users, setUsers] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);

    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);


    const [companyCodeOptions, setCompanyCodeOptions] = useState([]);
    const [selectedCompanyCode, setSelectedCompanyCode] = useState([]);


    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);


    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
   // const jwtToken = localStorage.getItem('token');

    // Fetch Project and User
    useEffect(() => {
        const fetchProjectAndUsers = async () => {
            try {
                const [projectResponse, userResponse, departmentResponse,companyCodeResponse] = await Promise.all([
                    axios.get(`${API_BASE_URL}/Project/get_all`),
                    axios.post(`${API_BASE_URL}/Users/get_users_list`),
                    axios.get(`${API_BASE_URL}/Department/get_all`),
                    axios.get(`${API_BASE_URL}/Project/get_all_company_code`),

                ]);
    
                // Extracting data based on the API response structure
                const projectsData = projectResponse.data?.data || [];
                const companycodeData = companyCodeResponse.data?.data || [];
                const usersData = userResponse.data?.results || [];
                const departmentsData = departmentResponse.data?.data || [];  // Adjusted for department structure
    
                setProjects(projectsData);
                setUsers(usersData);
    
                // Map project options
                const projectOptions = projectsData.map(prj => ({
                    name: prj.project_name,
                    value: prj.mst_projects_id,
                }));
                setProjectOptions(projectOptions);
    

                // const companycodeoption = projectsData.map(prj => ({
                //     name: prj.company_code,
                //     value: prj.mst_projects_id,
                // }));
                // setCompanyCodeOptions(companycodeoption);

                const companycodeoption = companycodeData.map(prj => ({
                    name: prj.company_code,
                    value: prj.company_code,
                }));
                setCompanyCodeOptions(companycodeoption);

    
                // Map employee options
                const employeesOptions = usersData.map(emp => ({
                    name: emp.employee_name,
                    value: emp.mst_users_id,
                }));
                setEmployeeOptions(employeesOptions);
    
                // Map department options
                const departmentOptions = departmentsData.map(dep => ({
                    name: dep.department_name,
                    value: dep.mst_departments_id,
                }));
                setDepartmentOptions(departmentOptions);
    
                console.log("Department IDs:", departmentsData.map(dep => dep.mst_departments_id));
            } catch (error) {
                console.error('Error fetching projects or users:', error);
                toast.error('Error fetching projects or users');
            }
        };
        fetchProjectAndUsers();
    }, [API_BASE_URL]);
    
    
    

    const fetchData = async (page, perPage) => {
        setLoading(true);
        try {
            const formData1 = new FormData();
            formData1.append("mst_users_id", localStorage.getItem('mst_users_id'));

            let apiEndpoint = `${API_BASE_URL}/Timesheet/get_all_timesheet`;
            let method = 'post';
         
            const response = await axios({
                method: method,
                url: apiEndpoint,
                data: formData1,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                params: {
                    page: page,
                    per_page: perPage
                }
            });

            setData(response.data.results);  
            setTotalRows(response.data.total); 
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(currentPage, perPage);
    }, [currentPage, perPage]);

  
    const handleCompanyCodeChange = (e) => {
        //alert(e.value);
        setSelectedCompanyCode(e.value);
        console.log('Selected CompanyCode:', e.value);
    };
    const handleProjectChange = (e) => {
        //alert(e.value);
        setSelectedProjects(e.value);
        console.log('Selected Projects:', e.value);
    };
    const handleEmployeeChange = (e) => {
        setSelectedEmployees(e.value);
        console.log('Selected Employee:', e.value);
    };
    

    const handleDepartmentChange = (e) => {
        setSelectedDepartments(e.value);
        console.log('Selected Department:', e.value);
    };

    const searchTable = async(page, perPage)=>{
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('employee_id', document.getElementsByClassName("employee_id")[0]?.value || '');
            formData.append('project_id', document.getElementsByClassName("project_id")[0]?.value || '');
            formData.append('company_code_project_id', document.getElementsByClassName("company_code_project_id")[0]?.value || '');
            formData.append('profit_center', document.getElementsByClassName("profit_center")[0]?.value || '');
            formData.append('department_id', document.getElementsByClassName("department_id")[0]?.value || '');
            formData.append('from_date', document.getElementsByClassName("from_date")[0]?.value || '');
            formData.append('to_date', document.getElementsByClassName("to_date")[0]?.value || '');
            formData.append('status', document.getElementsByClassName("status")[0]?.value || '');

            const response = await axios.post(
                `${API_BASE_URL}/Timesheet/get_all_timesheet_search`,
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                    params: { page, per_page: perPage }
                }
                
            );
            setData(response.data.results);
            setTotalRows(response.data.total);
            setLoading(false);
          
        } catch (error) {
            console.error('Error exporting filtered data:', error);
            toast.error('Failed to Search data');
        }
    }
    // useEffect(() => {
    //     searchTable(currentPage, perPage);
    // }, [currentPage, perPage]);

    const getProjectNameById = (id) => {
        const project = projects.find(pro => pro.mst_projects_id === id);
        return project ? project.project_name : <span className="text-danger">-</span>;
    };

    const getPCByProjectId = (id) => {
        const profit_center = projects.find(pro => pro.mst_projects_id === id);
        return profit_center ? profit_center.profit_center : <span className="text-danger">-</span>;
    };

    const getUserNameById = (id) => {
        const user = users.find(use => use.mst_users_id === id);
        return user ? user.employee_name : <span className="text-danger">-</span>;
    };
    const getTimesheetStatusById = (id) => {
       
        if (id === 0 || id === "0") {
            return <span className="badge bg-primary">Draft</span>;
        }
        if (id === 1 || id === "1") {
            return <span className="badge bg-warning">Submitted</span>;
        }
        if (id === 2 || id === "2") {
            return <span className="badge bg-success">Approved</span>;
        }
        
        // Default if no match
        return <span className="text-danger">Unknown</span>;
    };

    const getTimesheetStatusByIdExcel = (id) => {
       
        if (id === 0 || id === "0") {
            return 'Draft';
        }
        if (id === 1 || id === "1") {
            return 'Submitted';
        }
        if (id === 2 || id === "2") {
            return "Approved";
        }
        
        // Default if no match
        return <span className="text-danger">Unknown</span>;
    };
    

    const columns = [
        {
            name: 'SL.No.',
            selector: row => row.count,
            sortable: false,
        },
        {
            name: 'Project Name',
            selector: row => getProjectNameById(row.mst_projects_id),
            sortable: false,
        },
        {
            name: 'Profit Center',
            selector: row => getPCByProjectId(row.mst_projects_id),
            sortable: false,
        },
        {
            name: 'Employee',
            selector: row => getUserNameById(row.user_id),
            sortable: false,
        },
        {
            name: 'Department',
            selector: row => row.department_name,
            sortable: false,
        },
        {
            name: 'Company Code',
            selector: row => row.company_code,
            sortable: false,
        },
        {
            name: 'Status',
            cell: row => getTimesheetStatusById(row.status), // Use cell instead of selector
            sortable: false,
        },
        {
            name: 'Year',
            selector: row => row.year,
            sortable: false,
        },
        {
            name: 'Month',
            // selector: row => {
            //     const date = new Date(row.date); 
            //     const month = date.getMonth() + 1;
            //     return month;
            // },
            selector: row => row.month,
            sortable: true,
        },
        {
            name: 'Week No.',
            selector: row => row.week_no,
            sortable: false,
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
        },
        {
            name: 'Hrs',
            selector: row => row.hrs,
            sortable: false,
        }
    ];
    
    // Export to Excel
    const exportToExcel = () => {
        const exportData = data.map(item => ({
            'Project Name': getProjectNameById(item.mst_projects_id),
            'Profit Center': getPCByProjectId(item.mst_projects_id),
            'Employee': getUserNameById(item.user_id),
            'Department': item.department_name,
            'Company Code': item.company_code,
            'Status': getTimesheetStatusByIdExcel(item.status),
            'Year': item.year,
            'Month': item.month,
            'Week No': item.week_no,
            'Date': item.date,
            'Hrs': item.hrs
        }));

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Timesheet Data");
        XLSX.writeFile(workbook, "timesheet_data.xlsx");
    };

   
    const validateEmails = (emailString) => {
        const emails = emailString.split(',').map(email => email.trim());
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emails.every(email => emailRegex.test(email));
    };
    
    const send_email_excel = async () => {
        try {
            const formData = new FormData();
            const employeeId = document.getElementsByClassName("employee_id")[0]?.value || '';
            const projectId = document.getElementsByClassName("project_id")[0]?.value || '';
            const profitCenter = document.getElementsByClassName("profit_center")[0]?.value || '';
            const fromDate = document.getElementsByClassName("from_date")[0]?.value || '';
            const toDate = document.getElementsByClassName("to_date")[0]?.value || '';
            const status = document.getElementsByClassName("status")[0]?.value || '';
            
            formData.append('employee_id', employeeId);
            formData.append('project_id', projectId);
            formData.append('profit_center', profitCenter);
            formData.append('from_date', fromDate);
            formData.append('to_date', toDate);
            formData.append('status', status);
    
            const toEmailField = document.getElementsByClassName("to_email")[0];
            const toEmailValue = toEmailField?.value || '';
            formData.append('to_email', toEmailValue);
    
            const ccEmailField = document.getElementsByClassName("cc_email")[0];
            const ccEmailValue = ccEmailField?.value || '';
            formData.append('cc_email', ccEmailValue);
    
            // Validate To email field
            if (!validateEmails(toEmailValue)) {
                toEmailField.style.color = 'red';
                toast.error('Invalid To email(s). Please correct and try again.');
                return;
            } else {
                toEmailField.style.color = 'green';
            }
    
            // Validate CC email field
            if (ccEmailValue !== '' && !validateEmails(ccEmailValue)) {
                ccEmailField.style.color = 'red';
                toast.error('Invalid CC email(s). Please correct and try again.');
                return;
            } else if (ccEmailValue !== '') {
                ccEmailField.style.color = 'green';
            }
    
            const response = await axios.post(
                `${API_BASE_URL}/Usersdatafetch/send_email_timesheet_data`,
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }
            );
    
            alert(response.data.message);
    
            // Close modal using Bootstrap's modal method
            //$("#exampleModal").modal('hide');
    
            console.log(response.data.message);
        } catch (error) {
            console.error('Error exporting filtered data:', error);
            toast.error('Failed to export filtered data');
        }
    };
  

    const statusOptions = [
        { value: '0', label: 'Draft' },
        { value: '1', label: 'Submitted' },
        { value: '2', label: 'Approved' }
    ];
    const [status, setStatus] = useState(statusOptions[2]); 
    const handleStatusChange = (selectedOption) => {
        setStatus(selectedOption);
        console.log('Selected status:', selectedOption.value);
        document.getElementsByClassName("status").value=selectedOption.value;
    };
    // const handlePageChange = currentPage => {
    //     setCurrentPage(currentPage);
    //     setPerPage(currentPage)
    // };
 
    const handleSearch = () => {
        setCurrentPage(1); // Reset to the first page for a new search
        searchTable(1, perPage);
    };
    const handleRowsPerPageChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        searchTable(page, newPerPage);
    };
    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Timesheet Report</h4>
                            </div>
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-2 form-group">
                                            <label className="form-label">Project Name</label>
                                        
                                            <MultiSelect 
                                                value={selectedProjects}
                                                onChange={handleProjectChange}
                                                options={projectOptions} 
                                                optionLabel="name" 
                                                filter 
                                                placeholder="Select Project" 
                                                maxSelectedLabels={3} 
                                                className="w-full md:w-30rem " 
                                            />
                                             <input type="hidden" className="project_id" value={selectedProjects}/>
                                        </div>

                                        <div className="col-md-2 form-group">
                                            <label className="form-label">Company Code</label>
                                        
                                            <MultiSelect 
                                                value={selectedCompanyCode}
                                                onChange={handleCompanyCodeChange}
                                                options={companyCodeOptions} 
                                                optionLabel="name" 
                                                filter 
                                                placeholder="Select Company Code" 
                                                maxSelectedLabels={3} 
                                                className="w-full md:w-30rem " 
                                            />
                                             <input type="hidden" className="company_code_project_id" value={selectedCompanyCode}/>
                                        </div>

                                        <div className="col-md-2 form-group">
                                            <label className="form-label">Profit Center</label>
                                            <input
                                                type="text"
                                                placeholder="Search Profit Center"
                                                value={profit_center}
                                                onChange={e => setProfitCenter(e.target.value)}
                                                className="form-control profit_center"
                                            />
                                        </div>
                                        <div className="col-md-2 form-group">
                                            <label className="form-label">Employee</label>
                                           
                                            <MultiSelect 
                                                value={selectedEmployees}
                                                onChange={handleEmployeeChange}
                                                options={employeeOptions} 
                                                optionLabel="name" 
                                                filter 
                                                placeholder="Select Employee" 
                                                maxSelectedLabels={3} 
                                                className="w-full md:w-30rem" 
                                            />
                                             <input type="hidden" className="employee_id" value={selectedEmployees}/>
                                        </div>

                                        <div className="col-md-2 form-group">
                                            <label className="form-label">Department</label>
                                           
                                            <MultiSelect 
                                                value={selectedDepartments}
                                                onChange={handleDepartmentChange}
                                                options={departmentOptions} 
                                                optionLabel="name" 
                                                filter 
                                                placeholder="Select Department" 
                                                maxSelectedLabels={3} 
                                                className="w-full md:w-30rem" 
                                            />
                                             <input type="hidden" className="department_id" value={selectedDepartments}/>
                                        </div>

                                        <div className="col-md-2 form-group">
                                            <label className="form-label">From Date</label>
                                            <DatePicker
                                                selected={fromDate}
                                                onChange={date => setFromDate(date)}
                                                dateFormat="dd-MM-yyyy"
                                                isClearable
                                                className="form-control from_date"
                                                placeholderText="Select From Date "
                                            />
                                        </div>
                                        <div className="col-md-2 form-group">
                                            <label className="form-label">To Date</label>
                                            <DatePicker
                                                selected={toDate}
                                                onChange={date => setToDate(date)}
                                                dateFormat="dd-MM-yyyy"
                                                isClearable
                                                className="form-control to_date"
                                                placeholderText="Select To Date "
                                            />
                                        </div>
                                        <div className="col-md-2 form-group">
                                            <label className="form-label">Status</label>
                                         

<Select
    options={statusOptions}
    defaultValue={statusOptions[2]}
    placeholder="Select options"
    value={status}
    onChange={handleStatusChange}
    styles={{
        control: (provided) => ({
            ...provided,
            minHeight: '30px', // Minimum height for the dropdown
            padding: '0px', // Remove padding
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0px 8px', // Adjust horizontal padding, remove vertical padding
        }),
        input: (provided) => ({
            ...provided,
            margin: '0px', // Remove input margin
            padding: '0px', // Remove padding
        }),
        singleValue: (provided) => ({
            ...provided,
            lineHeight: '30px', // Align single value text to the center
        }),
        placeholder: (provided) => ({
            ...provided,
            lineHeight: '30px', // Align placeholder text to the center
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: '30px', // Adjust indicators container height
        }),
    }}
/>

                                               <input 
                                                    type="hidden" 
                                                    className='status' 
                                                    value={status ? status.value : ''} 
                                                />
                                        </div>
                                        <div className="col-md-2 form-group">
                                        <button 
                                            className="btn btn-success text-right mt-3" 
                                            onClick={() => handleSearch(currentPage, perPage)}
                                            >
                                            Search
                                        </button>

                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12 text-right">

                                           
                                            <button className="btn btn-primary" onClick={exportToExcel}>Export All</button>

                                            <button type="button" className="btn btn-primary send_email_excel" ata-bs-toggle="modal" data-bs-target="#exampleModal">Send Email</button>
                                            
                                        </div>
                                         <div className="col-md-6 text-right">

                                           

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <DataTable
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination={true}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        customStyles={{
                            headRow: {
                                style: {
                                    backgroundColor: '#0f6839',
                                    color: 'white'
                                },
                            },
                            headCells: {
                                style: {
                                    color: 'white',
                                    fontWeight: 'bold'
                                }
                            },
                            pagination: {
                                style: {
                                    padding: '10px'
                                }
                            }
                        }}
                    /> */}

<DataTable
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination={true}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                        paginationTotalRows={totalRows}
                        //onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        customStyles={{
                            headRow: {
                                style: {
                                    backgroundColor: '#0f6839',
                                    color: 'white',
                                    fontWeight: 'bold'
                                },
                            },
                            headCells: {
                                style: {
                                    color: 'white',
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap'
                                }
                            },
                            pagination: {
                                style: {
                                    padding: '10px'
                                }
                            }
                        }}
                    />
                </div>
            </div>
            <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Send Email</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="form-label">To Email</label>
                                    <textarea
                                        placeholder="To Email"
                                        value={toemail}
                                        onChange={e => setToemail(e.target.value)}
                                        className="form-control to_email"
                                        rows="3"
                                    ></textarea>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">CC Email</label>
                                    <textarea
                                        placeholder="CC Email"
                                        value={ccemail}
                                        onChange={e => setCCemail(e.target.value)}
                                        className="form-control cc_email"
                                        rows="3"
                                    ></textarea>
                                </div>
                                <div className="col-md-6">
                                    <button className="btn btn-primary mt-3" onClick={send_email_excel}>Send Email</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        

    );
};

export default Role;
