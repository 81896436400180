import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const MainLayout = ({ children }) => {
  return (
    <div id="layout-wrapper">
      <Header />
      <Sidebar />
      <div className="vertical-overlay"></div>
      {children}
    </div>
  );
};

export default MainLayout;
